import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

const FamecoExpansion = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`NMS Capital Portfolio Company Surplus Solutions Expands Internationally`}
          description={`NMS Capital Portfolio Company Surplus Solutions Expands Internationally; Announces Partnership with FAMECO Group ApS`}
          />
        <div className="row column">
            <h1>NMS Capital Portfolio Company Surplus Solutions Expands Internationally; Announces Partnership with FAMECO Group ApS</h1>
            <div className="row">
                <p>
                    <strong>New York, NY and Strasbourg, France, October 2, 2024</strong> – Surplus Solutions LLC (“Surplus Solutions”), a portfolio company of NMS Capital (“NMS”), announced today that it has expanded internationally via a partnership with FAMECO Group ApS (“FAMECO” or the “Company”). Terms of the transaction were not disclosed.
                </p>
                <p>
                    Founded in 2000 and headquartered in Strasbourg, France, FAMECO is an international leader in the sale and refurbishment of analytical laboratory and medical equipment. Servicing over 50 countries across Europe, Asia, and parts of Africa, the Company sources, refurbishes, and resells complex clinical and medical equipment spanning an array of use cases and end-markets. FAMECO employs more than 40 full-time individuals, maintains ISO-9001 certification, and houses an extensive inventory of over 3,000 systems and related parts within its state-of-the-art and centrally located headquarters.
                </p>
                <p>
                    Thomas Andersen, CEO and Co-Founder of FAMECO, stated, “From our initial meeting with the Surplus Solutions team, we recognized an alignment in our shared aims to provide high-quality secondhand equipment and related services to our customer universes.  We are thrilled to be partnering with the Surplus Solutions and NMS teams to accelerate our growth and build a best-in-class, international provider of equipment lifecycle services.”
                </p>
                <p>
                    “Representing our first acquisition since partnering with NMS in 2022, FAMECO marks the beginning of an exciting new chapter for Surplus Solutions,” said Joe Reale, CEO of Surplus Solutions. “We are excited to partner with the FAMECO team, gain an established foothold in fast-growing and highly fragmented international markets, and lay the groundwork for the rollout of refurbishment and technical services within the US. FAMECO’s established procedures and protocols with respect to refurbishment represent a synergistic and compelling opportunity when coupled with Surplus’ existing sourcing and sales activity.”
                </p>
                <p>
                    David Peterson, Partner at NMS, stated, “Since partnering with the Surplus Solutions team, two of our primary strategic objectives have been centered upon international expansion and the build-out of a technical service offering. The combination of Surplus Solutions and FAMECO allows us to deliver against both initiatives simultaneously and, in turn, represents an accretive development for the combined company. We look forward to continuing to build Surplus into a premier provider of equipment lifecycle management services through both organic and partnership-driven growth avenues in the years to come.”
                </p>
                <p>
                    McDermott Will & Emery and Accura provided legal advice to Surplus Solutions. Financing for the transaction was provided by Surplus’ incumbent lender, Twin Brook Capital Partners. Clearwater served as financial advisor to FAMECO while Gorrissen Federspiel provided legal advice.
                </p>
                <p>
                    <strong>About Surplus Solutions:</strong><br/>
                    Founded in 2006 and headquartered in Woonsocket, RI, Surplus Solutions is a leading provider of Equipment Lifecycle Management (ELM) services focused on procuring and reselling clinical laboratory and processing equipment from and to the biotechnology, pharmaceutical, and nutraceutical end-markets. For additional information on Surplus Solutions, please visit the firm's website at <a href="https://ssllc.com" target="_blank">ssllc.com</a>.
                </p>
                <p>
                    <strong>About NMS Capital:</strong><br/>
                    Founded in 2010, NMS Capital is an experienced private equity firm managing assets in excess of $1.5 billion. Since inception, NMS has partnered with management teams in over 150 investments and follow-on acquisitions across defined investment themes within the Business Services and Healthcare Services industries. The firm’s principal strategy is to create long-term value by providing strategic and operational resources to growth-oriented companies led by founders or experienced management teams. NMS has successfully built industry leading lower middle market companies in defensible and scalable end markets by accelerating organic and acquisition-driven growth. For additional information on NMS, please visit the firm’s website at <a href="https://nms-capital.com" target="_blank">nms-capital.com</a>. Follow NMS Capital on <a href="https://www.linkedin.com/company/nms-capital" target="_blank">LinkedIn</a>.
                </p>
                <p>
                    For further information:
                </p>
                <p>
                Colin Durney<br/>Vice President<br/>cdurney@nms-capital.com
              </p>
            </div>
        </div>

      </Layout>
    </>
  )
}

export default FamecoExpansion
